<template>
    <div class="chance">
      <v-toolbar>
        <router-link to="/">
          <v-btn
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </router-link>
        <v-toolbar-title>Назад</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-toolbar-title class="mr-lg-8 mr-md-6 mr-sm-4">Нюансы законодательства</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <div class="container">
        <div class="text-h5 mt-10">Статья 162 УК РФ. Разбой</div>
        <div style="font-size: 15px">
         <p>
           По этой статье предусмотрено лишение свободы на срок до 8 лет. Если разбой совершен группой лиц по
           предварительному сговору, а также с применением оружия, то на срок до 10 лет.
           Более тяжелые случаи - незаконное проникновение в помещение, крупный и особо крупный размер ущерба,
           причинение тяжкого вреда здоровью потерпевшего – повлекут утрату свободы на срок от 7 до 15 лет.
           Также в зависимости от тяжести преступления предусмотрены штрафы в размере до одного миллиона рублей.
         </p>
        </div>
        <div class="text-h5 mt-5">Статья 228 УК РФ. Незаконные приобретение, хранение, перевозка, изготовление,
          переработка наркотических средств, психотропных веществ или их аналогов, а также незаконные приобретение,
          хранение, перевозка растений, содержащих наркотические средства или психотропные вещества, либо их частей,
          содержащих наркотические средства или психотропные вещества</div>
        <div style="font-size: 15px">
          <p>
            По этой статье предусмотрено наказание: до 3 лет лишения свободы при значительном размере изъятых веществ,
            от 3 до 10 лет за крупный размер, от 10 до 15 лет за особо крупный размер. Дополнительно – штрафы и
            ограничение свободы. Возможно освобождение от уголовной ответственности в случае добровольной сдачи наркотиков и
            активной помощи раскрытию преступления.
          </p>
        </div>
        <div class="text-h5 mt-5">Статья 241 УК РФ. Организация занятия проституцией</div>
        <div style="font-size: 15px">
          <p>
            Наказание – штрафы, принудительные работы, лишение свободы на срок до 5 лет.
            Более тяжелое наказание грозит при использовании служебного положения, применении насилия,
            привлечении несовершеннолетних - лишение свободы на срок до 6 лет с ограничением
            свободы на срок до 2 лет. Если для занятия проституцией привлекались несовершеннолетние
            до 14 лет - лишение свободы на срок от 3 до 10 лет с ограничением свободы на срок от 1 года до 2 лет.
          </p>
        </div>
        <div class="text-h5 mt-5">Статья 264 УК РФ Нарушение правил дорожного движения и эксплуатации транспортных средств</div>
        <div style="font-size: 15px">
          <p>
            Если нарушение повлекло по неосторожности причинение тяжкого вреда здоровью человека, то предусмотрено
            наказание в виде лишения свободы на срок до 2 лет (варианты - ограничение свободы, принудительные работы, арест).
            Более тяжелые случаи - если нарушитель был пьян либо скрылся, и если нарушение привело к
            смерти человека – повлекут утрату свободы на срок от 3 до 15 лет в зависимости от тяжести и степени вины.
          </p>
        </div>
        <div class="text-h5 mt-5">Статья 290 УК РФ. Получение взятки</div>
        <div style="font-size: 15px">
          <p>
            Наказание в виде штрафов, исправительных или принудительных работ, лишения права занимать
            определенные должности, лишения свободы до 3 лет (за действия по служебным полномочиям),
            на срок до 6 лет (при значительном размере взятки), от 3 до 8 лет (за незаконные действия).
            За крупный и особо крупный размеры взятки, в случае ее вымогательства, а также за совершение
            преступления группой лиц грозит лишение свободы на срок от 7 до 15 лет с крупными штрафами.
          </p>
        </div>
        <div class="text-h5 mt-5">Статья 291 УК РФ. Дача взятки</div>
        <div style="font-size: 15px" class="mb-10">
          Наказание в виде штрафов, исправительных или принудительных работ, лишения права занимать определенные должности, лишения свободы до 2 лет (за действия по служебным полномочиям), на срок до 5 лет (при значительном размере взятки), до 8 лет (за незаконные действия). За крупный и особо крупный размеры взятки, в случае ее вымогательства, а также за совершение преступления группой лиц грозит лишение свободы на срок от 7 до 15 лет с крупными штрафами.
          <br>
          <br>
          Несмотря на перечисленные наказания, адвокаты применяют все возможные законные методы защиты на любой стадии. Цель защиты адвоката по уголовным делам – добиваться наилучшего положения своего доверителя, смягчение участи, признание невиновным. Нужно заметить, что такое не всегда возможно, но всегда надо стремиться к позитивному результату.
        </div>
      </div>
      <div style="background-color: #74AC48; padding: 40px 0" id="ya1">
        <div class="container pr-7 pr-lg-0 px-sm-8 pr-sm-11">
          <div style="font-size: 24px!important;color: white" class="title mb-5 mb-lg-8 mb-xl-12">
            <span style="color: #ffed00"> Бесплатная консультация адвоката</span> по уголовным делам
          </div>
          <v-form v-model="valid">
            <v-row>
              <v-col
                cols="12"
                md="3"
                class="pa-sm-0 px-md-1 pa-0 px-2"
              >
                <v-text-field
                  v-model="firstName"
                  :rules="nameRules"
                  :counter="10"
                  label="Имя"
                  solo
                  clearable
                  required
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                md="3"
                class="pa-sm-0 px-md-1 pa-0 px-2"
              >
                <v-text-field
                  v-model="phone"
                  :rules="phoneRules"
                  :counter="11"
                  label="Телефон"
                  solo
                  clearable
                  required
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                md="3"
                class="pa-sm-0 px-md-1 pa-0 px-2"
              >
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  solo
                  clearable
                  required
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="pa-sm-0 px-md-1 pa-0 px-2"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#404040"
                      style="color: #FFED00"
                      height="56"
                      width="100%"
                      @click="valid?send():false"
                      onclick="ym(87176674,'reachGoal','order')"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon v-if="statusSend">fas fa-circle-notch fa-spin</v-icon>
                      {{statusSend? '' : 'Получить'}}
                    </v-btn>
                  </template>
                  <span>{{!valid ? 'Заполните данные' : 'Отправить'}}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import querystring from 'querystring'
export default {
  name: 'chance',
  data () {
    return {
      valid: false,
      statusSend: false,
      firstName: '',
      phone: '',
      nameRules: [
        v => !!v || 'Введите имя',
        v => (v && v.length <= 10) || 'Имя не должно быть длиннее 10 символов'
      ],
      phoneRules: [
        value => !!value || 'Обязательное поле',
        value => (Boolean(Number(value))) || 'Введите только цифры',
        value => (value && value.length >= 11) || 'Не менее 11 цифр',
        value => (value && value.length <= 11) || 'Не более 11 цифр'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail обязательное поле',
        v => /.+@.+/.test(v) || 'E-mail введен не правильно'
      ]
    }
  },
  methods: {
    send () {
      if (this.valid) {
        this.statusSend = true
        const formData = new FormData()
        formData.append('crm', '16')
        formData.append('pipe', '34')
        formData.append('contact[name]', this.firstName)
        formData.append('contact[466]', this.phone)
        formData.append('contact[467]', this.email)
        formData.append('contact[473]', '')
        formData.append('lead[471]', '')
        formData.append('lead[534]', '')
        formData.append('lead[541]', 'pravo.dispozicia.ru')
        formData.append('lead[468]', '')
        formData.append('note', 'Заявка с pravo.dispozicia.ru')

        const formToMail = {
          name: this.firstName,
          email: this.email,
          phone: this.phone,
          message: 'Заявка с pravo.dispozicia.ru со страницы: ' + this.$route.path
        }
        axios.post('/send.php', querystring.stringify(formToMail)).then(res => (res.status === 200 && console.log('success send to mail')))

        axios.post('https://bez.v-avtoservice.com/api/import-lead',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(res => {
          alert('Заявка отправлена!')
          this.statusSend = false
        })
      } else return false
    }
  }
}
</script>

<style scoped>

</style>
