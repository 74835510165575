<template>
  <div class="ebsa">
    <v-toolbar>
      <router-link to="/">
        <v-btn
          icon
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </router-link>

      <v-toolbar-title>Назад</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-lg-8 mr-md-6 mr-sm-4">УЗНАТЬ ШАНСЫ ПО СВОЕМУ ДЕЛУ</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <div class="container">
      <p class="text-h6 mt-5">Стоит ли нанимать адвоката?</p><br>
      Нужно ли рисковать и платить 50, 100 и более тысяч рублей, когда знакомые говорят, что это бесполезно и все равно
      «засудят»? С другой стороны, судебная практика доказывает, что бороться можно и нужно.<br>
      Можно самому собрать информацию в Интернете и по «сарафанному радио», разобраться, как все устроено.
      Собрать судебные акты, сравнить их с особенностями своего
      дела, проанализировать и сделать выводы.<br>
      А можно поступить по-другому – гораздо проще – ответить на несколько вопросов
      (ведь дела обычно имеют одну основу и структуру, хотя кажется все непонятным и очень запутанным)
      и получить ответ в виде решения суда по аналогичному делу, и это позволит понять, какие у вас есть
      шансы на позитивное решение проблемы.
      <br>
      Узнать свои шансы можно прямо сейчас. Заполните данные и получите ответ на электронную почту или мессенджер.
      <br>
      Готовы?<br>
    </div>
    <div class="hello">
      <div class="back-image">
        <img src="../assets/ebsa/background-img.svg" alt="">
      </div>
      <div>
        <div class="container">
          <div class="hello-head">
            <div class="hello-head_title">
              <div class="subtitle mt-10">
                <p>Выбирайте статью</p>
                <p>Получите 2 судебных акта по такому же делу, как у вас</p>
                <p>Все удобно и бесплатно</p>
                <svg width="30" height="240" viewBox="0 0 30 240" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.5858 239.414C14.3668 240.195 15.6332 240.195 16.4142 239.414L29.1421 226.686C29.9232 225.905 29.9232 224.639 29.1421 223.858C28.3611 223.077 27.0948 223.077 26.3137 223.858L15 235.172L3.6863 223.858C2.90525 223.077 1.63892 223.077 0.857874 223.858C0.0768254 224.639 0.0768255 225.905 0.857874 226.686L13.5858 239.414ZM13 8.74228e-08L13 238L17 238L17 -8.74228e-08L13 8.74228e-08Z" fill="#20D152"/>
                </svg>
                <a href="#ya1" class="btn">Получить решения</a>
              </div>
            </div>
          </div>
        </div>
        <div class="hello-check">
          <div class="hello-check_what">
            <div class="hello-check_what-articles">
              <h2>ПОЛУЧИТЕ РЕШЕНИЯ ПО СТАТЬЯМ УК РФ</h2>
              <div @click="showForm = numArticle[0]" class="articles-container">
                <div class="title">{{numArticle[0]}}</div>
                <div class="subtitle">Разбой</div>
              </div>
              <div @click="showForm = numArticle[1]" class="articles-container">
                <div class="title">{{numArticle[1]}}</div>
                <div class="subtitle">Незаконные приобретение, хранение.. наркотических средств..</div>
              </div>
              <div @click="showForm = numArticle[2]" class="articles-container">
                <div class="title">{{numArticle[2]}}</div>
                <div class="subtitle">Организация занятия проституцией</div>
              </div>
              <div @click="showForm = numArticle[3]" class="articles-container">
                <div class="title">{{numArticle[3]}}</div>
                <div class="subtitle">Нарушение правил дорожного движения..</div>
              </div>
              <div @click="showForm = numArticle[4]" class="articles-container">
                <div class="title">{{numArticle[4]}}</div>
                <div class="subtitle">Получение взятки</div>
              </div>
              <div @click="showForm = numArticle[5]" class="articles-container">
                <div class="title">{{numArticle[5]}}</div>
                <div class="subtitle">Дача взятки</div>
              </div>
              <div @click="showForm = numArticle[6]" class="articles-container">
                <div class="title">{{numArticle[6]}}</div>
                <div class="subtitle">Позвоните мне. У меня другая ситуация</div>
              </div>
              <div v-show="showForm" ref="form" class="v-services_form">
                <div class="title">
                  {{showForm !== 'Другое' ? 'Статья ' + showForm : showForm}}
                  <p>Получите бесплатно судебные решения и выиграйте свое дело</p>
                </div>
                <form>
                  <div class="form-wrapper">
                    <label>
                      <span>Ваше имя</span>
                      <input type="text" v-model="name" placeholder="Введите имя">
                    </label>
                    <label>
                      <span>E-mail</span>
                      <input type="email" v-model="email" placeholder="E-mail">
                    </label>
                    <label>
                      <span>Телефон</span>
                      <input type="text" class="new-input" required placeholder="Ваш телефон" data-inputmask="'mask': '+9(999)999-99-99'"  v-model="phone">
                    </label>
                  </div>
                </form>
                <button type="submit" @click="send" onclick="ym(87176674,'reachGoal','order')">Получить судебные решения</button>
                <p>Отправляя форму вы даёте согласие на обработку персональных данных</p>
              </div>
              <br id="ya1">
            </div>
          </div>
        </div>
      </div>
      <div class="progress-bar" v-show="isProgressBar">
        <div v-show="uploadPercentage < 100" class="title">Отправление:<br><span>{{uploadPercentage}}%</span> из <span>100%</span></div>
        <div v-show="uploadPercentage === 100" class="title">Заявка удачно отправлена!</div>
        <progress max="100" :value.prop="uploadPercentage"></progress>
      </div>
      <notice
        v-if="isPopup"
        @removePopup="removePopup"
        :isPopup="isPopup"
        :title="title"
        :subtitle="subtitle"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import notice from './notification'
import querystring from 'querystring'
export default {
  name: 'Ebsa',
  components: { notice },
  data () {
    return {
      numArticle: [
        '162', '228', '241', '264', '290', '291', 'Другое'
      ],
      showForm: false,
      name: null,
      email: null,
      phone: null,
      isPopup: false,
      title: null,
      subtitle: null,
      isProgressBar: false,
      uploadPercentage: 0
    }
  },
  methods: {
    removePopup () {
      this.isPopup = false
    },
    send (e) {
      if (!this.name) {
        e.target.innerText = 'Вам необходимо указать как вас зовут'
        e.target.classList.add('error-send')
        this.isPopup = true
        this.title = 'Вам необходимо указать как вас зовут'
        this.subtitle = 'Пожалуйста, укажите Ваше имя. Это необходимо для более качественного предоставления услуг'
        setTimeout(() => {
          e.target.innerText = 'Узнать результаты'
          e.target.classList.remove('error-send')
        }, 800)
      } else if (!this.phone) {
        e.target.innerText = 'Вам необходимо указать email или телефон'
        e.target.classList.add('error-send')
        this.isPopup = true
        this.title = 'Вам необходимо указать Ваш номер телефона'
        this.subtitle = 'Пожалуйста, укажите Ваш телефон. Это необходимо для более качественного предоставления услуг. Ваши данные надежно защищены'
        setTimeout(() => {
          e.target.innerText = 'Узнать результаты'
          e.target.classList.remove('error-send')
        }, 800)
      } else {
        e.target.innerText = 'Ждите..'
        const formData = new FormData()
        formData.append('crm', '16')
        formData.append('pipe', '34')
        formData.append('contact[name]', this.name)
        formData.append('contact[466]', this.phone)
        formData.append('contact[467]', this.email)
        formData.append('contact[473]', '')
        formData.append('lead[471]', '')
        formData.append('lead[534]', '')
        formData.append('lead[541]', 'pravo.dispozicia.ru')
        formData.append('lead[468]', '')
        formData.append('note', 'Заявка с pravo.dispozicia.ru по статье: ' + this.showForm)

        this.isProgressBar = true

        const formToMail = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          message: 'Заявка с pravo.dispozicia.ru со страницы: ' + this.$route.path
        }
        axios.post('/send.php', querystring.stringify(formToMail)).then(res => (res.status === 200 && console.log('success send to mail')))

        axios.post('https://bez.v-avtoservice.com/api/import-lead',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
            }.bind(this)
          }
        ).then(res => {
          setTimeout(() => { this.isProgressBar = false }, 2500)
          document.getElementById('modalSubscribe').style.display = 'block'
          // document.getElementById('answer').style.display = 'block'
          this.$refs.form.remove()
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .ebsa {
  }
  .hello {
    .back-image {
      position: absolute;
      right: 0;
      top: 40%;
      z-index: 0;
    }
    .hello-head {
      display: flex;
      flex-wrap: wrap;
      .hello-head_title {
        .title {
          display: flex;
          flex-wrap: wrap;
          h1 {
            flex-basis: 50%;
            margin: 0 40px 50px 0;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 800;
            font-size: 54px;
            line-height: 68px;
            color: rgba(0, 0, 0, 0.72);
          }
          p {
            position: absolute;
            top: 320px;
            font-style: normal;
            font-weight: normal;
            font-size: 28px;
            line-height: 34px;
            color: rgba(0, 0, 0, 0.4);
          }
          .hello-head_img {
            position: relative;
            flex-basis: 40%;
            img {
              max-width: 100%;
            }
            span {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              bottom: calc(10% + 50px);
              left: -30px;
              width: 340px;
              height: 70px;
              background: #FFFFFF;
              box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
              font-style: normal;
              font-weight: bold;
              font-size: 28px;
              line-height: 34px;
              text-align: center;
              color: #0F8831;
              z-index: 1;
            }
          }
        }
        .subtitle {
          display: flex;
          flex-direction: column;
          position: relative;
          padding-left: 55px;
          font-size: 16px;
          line-height: 20px;
          svg {
            bottom: 0;
            height: calc(240px - 10px);
            width: 30px;
            position: absolute;
            left: -5px;
          }
          &::before {
            content: '';
            top: 20px;
            left: 8px;
            background: #20D152;
            background-size: cover;
            position: absolute;
            width: 4px;
            height: calc(100% - 50px);
          }
          p::before {
            content: '';
            left: 5px;
            background-color: #FFFFFF;
            position: absolute;
            border-radius: 10px;
            width: 8px;
            height: 8px;
            border: 1px solid #20D152;
            z-index: 5;
          }
          p {
            margin-bottom: 18px;
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
            color: #FFFFFF;
            font-size: 16px;
            width: 180px;
            max-width: 180px;
            min-width: 140px;
            height: 60px;
            background: #20D152;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
            border-radius: 6px;
          }
        }
      }
    }
    .hello-check {
      padding: 45px 0 45px 45px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      border-radius: 16px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF;
      margin-top: 60px;
      .hello-check_what {
        .hello-check_what-title {
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
        }
        .hello-check_what-subtitle {
          display: flex;
          flex-wrap: wrap;
          justify-content: initial;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          & p:first-child {
            margin-right: 30px;
          }
          p {
            max-width: 460px;
            span {
              float: left;
              height: 100%;
            }
          }
        }
        .hello-check_what-articles {
          display: flex;
          flex-wrap: wrap;
          h2 {
            flex-basis: 100%;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
          }
          .articles-container {
            cursor: pointer;
            width: 180px;
            height: 140px;
            font-size: 14px;
            margin: 10px;
            padding: 10px 0 0 10px;
            background: rgba(32, 209, 82, 0.08);
            border: 1px solid rgba(32, 209, 82, 0.16);
            box-sizing: border-box;
            border-radius: 10px;
            position: relative;
            &::before {
              content: '';
              width: 25px;
              height: 15px;
              bottom: 10px;
              right: 10px;
              position: absolute;
              background: url("../assets/icon/arrow.svg") center no-repeat;
            }
            .title {
              margin-bottom: 15px;
              font-weight: 600;
              font-size: 20px;
              line-height: 28px;
              color: #20D152;
            }
            &:hover .title {
              color: #FFFFFF;
            }
            &:hover {
              background: #20D152;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .progress-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #0f8831;
      position: fixed;
      top: 50%;
      padding: 10px;
      left: calc(50% - 100px);
      border-radius: 5px;
      color: white;
      -webkit-box-shadow: -1px 5px 54px -2px rgba(0,0,0,0.59);
      -moz-box-shadow: -1px 5px 54px -2px rgba(0,0,0,0.59);
      box-shadow: -1px 5px 54px -2px rgba(0,0,0,0.59);
      .title {
        margin-bottom: 5px;
        span {
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
  }
  .v-services_form {
    margin: 20px auto;
    width: 100%;
    .title {
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .form-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 24px;
        label {
          margin: 10px 5px;
          position: relative;
          max-width: 30%;
          min-width: 200px;
          width: 30%;
          &:focus {
            color: #0F8831;
          }
          input {
            width: 100%;
            border: 1px solid #20D152;
            box-sizing: border-box;
            border-radius: 6px;
            height: 48px;
            padding: 20px 14px;
            &:focus {
              color: #0F8831;
            }
          }
          span {
            position: absolute;
            top: -15px;
            left: 5px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
    button {
      display: block;
      width: 220px;
      height: 48px;
      background: #20D152;
      margin: 0 auto;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 16px;
    }
    p {
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.36);
    }
  }
  @media (max-width: 1080px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            h1 {
            }
            p {
              top: 270px;
            }
          }

          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          min-width: 300px;
          img {
            max-width: 90vw;
          }
          span {
            bottom: initial;
            top: 60%;
          }
        }
      }
    }
  }
  @media (max-width: 550px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            h1 {
              font-size: 24px;
              line-height: 28px;
            }
            p {
              top: 200px;
              font-size: 18px;
              line-height: 14px;
              margin-bottom: 20px;
            }
          }

          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          min-width: 100%;
          img {
            max-width: 90vw;
          }
          span {
            bottom: initial;
            top: 60%;
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            h1 {
              font-size: 24px;
              line-height: 28px;
            }
            p {
              font-size: 18px;
              line-height: 14px;
            }
          }
          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          max-width: 100%;
          img {
            max-width: 90vw;
          }
          span {
            width: 200px;
            height: 40px;
            font-size: 18px;
            top: 70%;
            left: -5px;
          }
        }
      }
      .hello-check {
        padding: 20px 0 45px 10px;
        .hello-check_what {
          display: flex;
          flex-direction: column;
          align-items: center;
          .hello-check_what-title {
          }
          .hello-check_what-subtitle {
            & p:first-child {
            }
            p {
              span {
              }
            }
          }
          .hello-check_what-articles {
            h2 {
            }
            .articles-container {
              width: calc(100% - 30px);
              height: 110px;
              .title {
              }
              &:hover .title {
              }
              &:hover {
              }
            }
          }
        }
      }
    }
    .v-services_form {
      .title {
      }
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .form-wrapper {
          label {
            margin: 10px 5px;
            position: relative;
            max-width: initial;
            min-width: 200px;
            width: 100%;
          }
        }
      }
      button {
        display: block;
        width: 220px;
        height: 48px;
        background: #20D152;
        margin: 0 auto;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 16px;
      }
      p {
        text-align: center;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.36);
      }
    }
  }
  @media (max-width: 330px) {
    .hello {
      .hello-head {
        justify-content: center;
        .hello-head_title {
          .title {
            p {
              top: 230px;
            }
          }
          .subtitle {
            font-size: 13px;
            line-height: 16px;
          }
        }
        .hello-head_img {
          max-width: 100%;
          img {
            max-width: 90vw;
          }
          span {
            width: 200px;
            height: 40px;
            font-size: 18px;
            top: 70%;
            left: -5px;
          }
        }
      }
      .hello-check {
        padding: 20px 0 45px 10px;
        .hello-check_what {
          display: flex;
          flex-direction: column;
          align-items: center;
          .hello-check_what-title {
          }
          .hello-check_what-subtitle {
            & p:first-child {
            }
            p {
              span {
              }
            }
          }
          .hello-check_what-articles {
            h2 {
            }
            .articles-container {
              width: calc(100% - 30px);
              height: 110px;
              .title {
              }
              &:hover .title {
              }
              &:hover {
              }
            }
          }
        }
      }
    }
  }
</style>
