<template>
    <v-app app>
      <div class="wins">
        <v-card>
          <v-divider></v-divider>
          <div class="d-flex align-center">
            <router-link to="/">
              <v-btn
                text
              >
                <v-icon>mdi-chevron-left</v-icon>
                Назад
              </v-btn>
            </router-link>
            <v-divider class="ml-4" vertical/>
            <div class="title ml-4 my-8">ВЫИГРАННЫЕ ДЕЛА</div>
          </div>
          <v-divider></v-divider>
          <section class="reviews">
            <div class="container">
              <div class="reviews-wrap">
                <div class="reviews-container">
                  <div class="reviews-title">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mb-3">Ст. 162 УК РФ</v-list-item-subtitle>
                        <div class="d-flex">
                          <img style="max-width: 200px" class="mr-4" src="../assets/wins/162ysl.jpg" alt="">
                          <p class="mt-6" style="float: left">
                            Условный срок по ст. 162 ч.2 УК РФ.<br>
                            Нашему доверителю назначено наказание в виде лишения свободы сроком на 4 года условно,
                            без штрафа и ограничения свободы. Была, в частности, доказана неактивная
                            роль в преступлении, подтверждены другие смягчающие обстоятельства.
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
                <div class="reviews-container">
                  <div class="reviews-title">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mb-3">Ст. 162 УК РФ</v-list-item-subtitle>
                        <div class="d-flex">
                          <img style="max-width: 200px" class="mr-4" src="../assets/wins/162.jpg" alt="">
                          <p class="mt-6" style="float: left">
                            Наш клиент оправдан по ст. 162 ч.2 УК РФ в связи с непричастностью к совершению преступления.
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="reviews-container">
                  <div class="reviews-title">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mb-3">Ст. 228 УК РФ</v-list-item-subtitle>
                        <div class="d-flex">
                          <img style="max-width: 200px" class="mr-4" src="../assets/wins/228.jpg" alt="">
                          <p>
                            Условный срок по ст. 228 ч.2 УК РФ<br>
                            Суд назначил нашему подзащитному условный срок. Наш подзащитный вину признал полностью, раскаялся, также судом были учтены смягчающие обстоятельства - хранение наркотических веществ без цели сбыта, наличие хронических заболеваний, наличие на иждевении матери-инвалида, совершение преступления впервые, положительные характеристики с места работы и жительства.
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="reviews-container">
                  <div class="reviews-title">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mb-3">Ст. 264 УК РФ</v-list-item-subtitle>
                        <div class="d-flex">
                          <img style="max-width: 200px" class="mr-4" src="../assets/wins/264.jpg" alt="">
                          <p>
                            Уголовное дело по ст. 264 ч.1 УК РФ<br>
                            в отношении нашего клиента прекращено. Это достигнуто за счет принесения извинений
                            пострадавшей, выплаты ей компенсации, в связи с чем пострадавшая заявила в суде
                            о примирении и ходатайствовала о прекращении дела.
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="reviews-container">
                  <div class="reviews-title">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mb-3">Ст. 241 УК РФ </v-list-item-subtitle>
                        <div class="d-flex">
                          <img style="max-width: 200px" class="mr-4" src="../assets/wins/241.jpg" alt="">
                          <p>
                            Штраф по ст. 241 ч.1 УК РФ.<br>
                            Суд назначил нашему клиенту наказание в виде штрафа за систематическое предоставление помещения для занятия проституцией, освободил от уголовной ответственности.
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="reviews-container">
                  <div class="reviews-title">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mb-3">Ст. 290 УК РФ</v-list-item-subtitle>
                        <div class="d-flex">
                          <img style="max-width: 200px" class="mr-4" src="../assets/wins/290.jpg" alt="">
                          <p>
                            Наш клиент оправдан по ст. 290 ч.2 УК РФ в связи с отсутствием в его действиях состава преступления.
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="reviews-container">
                  <div class="reviews-title">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mb-3">Ст. 291 УК РФ</v-list-item-subtitle>
                        <div class="d-flex">
                          <img style="max-width: 200px" class="mr-4" src="../assets/wins/291.jpg" alt="">
                          <p>
                            Штраф по ст. 291 ч. 3 УК РФ.<br>
                            Нашему подзащитному назначен штраф в размере ниже определенного по ч.3 ст. 291 УК РФ.
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </v-card>
      </div>
    </v-app>
</template>

<script>
export default {
  name: 'Wins'
}
</script>

<style scoped lang="scss">

</style>
